import { Button, Form, Input, Select } from 'antd';
import { includes } from 'lodash';
import React, { useState } from 'react';
import { REQUEST_STATUS } from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';

const { Item } = Form;
const { TextArea } = Input;

const ViewCommentsModal = ({
  isModalOpen,
  setIsModalOpen,
  isPendingComment,
  status,
  form,
  onFinish,
  isLibrarian = false,
  damage,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        setIsEditable(false);
        form?.resetFields();
      }}
      footer={
        // eslint-disable-next-line no-nested-ternary
        isLibrarian ||
        includes(
          [REQUEST_STATUS?.RESOLVED?.value, REQUEST_STATUS?.APPROVED?.value],
          status,
        ) ? (
          []
        ) : isEditable ? (
          <>
            <Button
              onClick={() => {
                setIsModalOpen(false);
                setIsEditable(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form?.submit();
                setIsEditable(false);
                setIsModalOpen(false);
              }}
            >
              Save
            </Button>
          </>
        ) : (
          <Button type="primary" onClick={() => setIsEditable(true)}>
            Edit
          </Button>
        )
      }
    >
      <div className="mb-16">
        <b className="font-size-16">
          {isPendingComment
            ? REQUEST_STATUS?.PENDING?.text
            : REQUEST_STATUS?.RESOLVED?.text}{' '}
          comment by admin
        </b>
      </div>
      <div className="font-size-16 d-flex flex-vertical">
        <div className="font-size-16 mb-16">
          Date:{' '}
          {isPendingComment
            ? formatDate(damage?.metaData?.pendingComment?.updatedAt)
            : formatDate(damage?.metaData?.resolveComment?.updatedAt) || '-'}
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => setValidationTriggered(true)}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        >
          <Item
            name="reason"
            label="Reason"
            rules={[{ required: true, message: 'Please Select Reason!' }]}
          >
            <Select
              placeholder="Select Reason"
              disabled={!isEditable}
              options={[
                { label: 'Reason 1', value: 'reason1' },
                { label: 'Reason 2', value: 'reason2' },
                { label: 'Reason 3', value: 'reason3' },
                { label: 'Reason 4', value: 'reason4' },
              ]}
            />
          </Item>
          <Item name="comment" label="Comment">
            <TextArea
              disabled={!isEditable}
              rows={4}
              allowClear
              placeholder="Enter Comment"
              showCount
              maxLength={700}
            />
          </Item>
        </Form>
      </div>
    </ModalComponent>
  );
};

export default ViewCommentsModal;
