import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ASSIGN_SUBSCRIPTION = gql`
  mutation AssignSubscription(
    $where: UserUniqueInput!
    $data: AssignSubscriptionInput!
  ) {
    assignSubscription(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_USER_SUBSCRIPTION_PLAN = gql`
  mutation UpdateUserSubscriptionPlan(
    $where: UserSubscriptionUniqueInput!
    $data: UpdateUserSubscriptionPlanInput!
  ) {
    updateUserSubscriptionPlan(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_USER_SUBSCRIPTION = gql`
  mutation UpdateUserSubscription(
    $where: UserSubscriptionUniqueInput!
    $data: UpdateUserSubscriptionInput!
  ) {
    updateUserSubscription(where: $where, data: $data) {
      message
    }
  }
`;

export const ADD_USER_LIBRARY = gql`
  mutation AddUserLibrary($data: AddUserLibraryInput!) {
    addUserLibrary(data: $data) {
      message
    }
  }
`;

export const REMOVE_USER_LIBRARY = gql`
  mutation RemoveUserLibrary($where: UserLibraryUniqueInput!) {
    removeUserLibrary(where: $where) {
      message
    }
  }
`;
