import { Divider, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import {
  LIBRARY_DAMAGE_REQUEST_PENDING_COMMENT_REASONS,
  LIBRARY_DAMAGE_REQUEST_RESOLVED_COMMENT_REASONS,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';

const { Item } = Form;
const { TextArea } = Input;
const { required, whitespaceNotAllowed } = formValidatorRules;

const CommentModal = ({
  form,
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
  isPendingComment = false,
  commentRequired = false,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  return (
    <ModalComponent
      title={isPendingComment ? 'Pending Comment' : 'Resolving Comment'}
      okText="Save"
      cancelText="Cancel"
      open={isModalOpen}
      onOk={form?.submit}
      onCancel={() => {
        setIsModalOpen(false);
        form?.resetFields();
      }}
      confirmLoading={isBtnLoading}
    >
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => setValidationTriggered(true)}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Item
          name="reason"
          label="Reason"
          rules={[{ required: true, message: 'Please Select Reason!' }]}
        >
          <Select
            placeholder="Select Reason"
            options={
              isPendingComment
                ? LIBRARY_DAMAGE_REQUEST_PENDING_COMMENT_REASONS
                : LIBRARY_DAMAGE_REQUEST_RESOLVED_COMMENT_REASONS
            }
          />
        </Item>
        <Item
          name="comment"
          label="Comment"
          rules={
            commentRequired
              ? [
                  { ...required, message: 'Please Enter Comment!' },
                  whitespaceNotAllowed,
                ]
              : [whitespaceNotAllowed]
          }
        >
          <TextArea
            rows={4}
            allowClear
            placeholder="Enter Comment"
            showCount
            maxLength={700}
          />
        </Item>
      </Form>
    </ModalComponent>
  );
};

export default CommentModal;
