import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Form,
  Image,
  Modal,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/svg/trash-solid.svg';
import {
  BREAKPOINTS,
  EXPORT_TYPES,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  PRODUCT_TYPE,
  ROUTES,
  SORT,
} from '../../../../../common/constants';
import {
  fileUpload,
  hasPermission,
  openNotification,
} from '../../../../../common/utils';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import {
  AGE_GROUPS,
  BRANDS_ADMIN,
  CATEGORIES,
  FACILITATES_ADMIN,
  MATERIALS_ADMIN,
  TAGS_ADMIN,
} from '../../toy/graphql/Queries';
import {
  ADD_TOY_TO_LIBRARY_INVENTORY,
  CREATE_BULK_LIBRARY_INVENTORY,
  DELETE_TOY_FROM_INVENTORY,
  LIBRARY_INVENTORY_BULK_UPDATE_ADMIN,
  REQUEST_LIBRARY_INVENTORY_EXPORT_ADMIN,
  UPDATE_LIBRARY_INVENTORY_ADMIN,
} from '../graphql/Mutations';
import {
  GET_BULK_CREATE_LIBRARY_INVENTORY_SIGNED_PUT_URL_ADMIN,
  GET_SIGNED_BULK_UPDATE_URL_ADMIN,
  LIBRARY_INVENTORIES_ADMIN,
} from '../graphql/Queries';
import AddToysModal from './AddToysModal';
import BulkUpdateModal from './BulkUpdateModal';
import EditInventoryModal from './EditInventoryModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialToyFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialToySort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'name',
  order: 'ASC',
};

let filterScrollDebounceJob = null;

const InventoryTable = ({
  libraryId,
  libraryNumber,
  isView,
  isAddToyModalOpen,
  setIsAddToyModalOpen,
  exportFilter,
  setExportFilter,
}) => {
  const {
    state: {
      permissions,
      isDesktop,
      commonPermissions: { isToyPermission },
    },
  } = useContext(AppContext);
  const [toyForm] = Form.useForm();
  const [bulkUpdateForm] = Form.useForm();
  const history = useHistory();
  const [editInventoryForm] = Form.useForm();
  const [inventoryList, setInventoryList] = useState([]);
  const [inventorySearchTerm, setInventorySearchTerm] = useState('');
  const [isEmptyInventoryList, setIsEmptyInventoryList] = useState(false);
  const [inventory, setInventory] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [totalToysDetails, setTotalToysDetails] = useState({
    totalRentedOut: 0,
    totalAvailableStock: 0,
  });
  const [bulkUpdateFile, setBulkUpdateFile] = useState([]);
  const [toyFile, setToyFile] = useState([]);
  const [toySelectValue, setToySelectValue] = useState([]);

  const [isDeletePrompts, setIsDeletePrompts] = useState(false);
  const [isInventoryLoading, setIsInventoryLoading] = useState(true);
  const [isAddToysLoading, setIsAddToysLoading] = useState(false);
  const [isEditInventoryLoading, setIsEditInventoryLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isBulkUpdateLoading, setIsBulkUpdateLoading] = useState(false);

  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const [isEditInventoryModalOpen, setIsEditInventoryModalOpen] = useState(
    false,
  );

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isGoExportVisible, setIsGoExportVisible] = useState(false);

  const [addToysToLibraryInventory] = useMutation(
    ADD_TOY_TO_LIBRARY_INVENTORY,
    {
      onError: () => {},
    },
  );
  const [updateLibraryInventoryAdmin] = useMutation(
    UPDATE_LIBRARY_INVENTORY_ADMIN,
    {
      onError: () => {},
    },
  );
  const [requestLibraryInventoryExportAdmin] = useMutation(
    REQUEST_LIBRARY_INVENTORY_EXPORT_ADMIN,
    {
      onError: () => {},
    },
  );
  const [deleteToyFromInventory] = useMutation(DELETE_TOY_FROM_INVENTORY, {
    onError: () => {},
  });
  const [getSignUrl] = useLazyQuery(GET_SIGNED_BULK_UPDATE_URL_ADMIN, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  const [getBulkCreateLibraryInventorySignedPutUrlAdmin] = useLazyQuery(
    GET_BULK_CREATE_LIBRARY_INVENTORY_SIGNED_PUT_URL_ADMIN,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );
  const [libraryInventoryBulkUpdateAdmin] = useMutation(
    LIBRARY_INVENTORY_BULK_UPDATE_ADMIN,
    {
      onError: () => {},
    },
  );
  const [createBulkLibraryInventory] = useMutation(
    CREATE_BULK_LIBRARY_INVENTORY,
    {
      onError: () => {},
    },
  );

  const [libraryInventoriesAdmin] = useLazyQuery(LIBRARY_INVENTORIES_ADMIN, {
    onCompleted: (response) => {
      setInventoryList([...response?.libraryInventoriesAdmin?.data]);
      if (
        response?.libraryInventoriesAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyInventoryList(true);
      } else {
        setIsEmptyInventoryList(false);
      }
      setTotalToysDetails({
        totalRentedOut: response?.libraryInventoriesAdmin?.totalRentedOut,
        totalAvailableStock:
          response?.libraryInventoriesAdmin?.totalAvailableStock,
      });
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.libraryInventoriesAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsInventoryLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [brandsAdmin, { loading: isBrandsLoading }] = useLazyQuery(
    BRANDS_ADMIN,
    {
      onCompleted: (response) => {
        if (response?.catalogBrandsAdmin?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.catalogBrandsAdmin?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.catalogBrandsAdmin?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [categories, { loading: isCategoriesLoading }] = useLazyQuery(
    CATEGORIES,
    {
      onCompleted: (response) => {
        if (response?.categories?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.categories?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.categories?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [facilitatesAdmin, { loading: isFacilitateLoading }] = useLazyQuery(
    FACILITATES_ADMIN,
    {
      onCompleted: (response) => {
        if (response?.facilitatesAdmin?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.facilitatesAdmin?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.facilitatesAdmin?.data, (item) =>
          optionsCopy?.push({
            label: item?.name?.replace(/\s/g, '\u00a0'),
            key: item?.id,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [tagsAdmin, { loading: isTagLoading }] = useLazyQuery(TAGS_ADMIN, {
    onCompleted: (response) => {
      if (response?.tagsAdmin?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.tagsAdmin?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.tagsAdmin?.data, (item) =>
        optionsCopy?.push({
          label: item?.name?.replace(/\s/g, '\u00a0'),
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [materialsAdmin, { loading: isMaterialsLoading }] = useLazyQuery(
    MATERIALS_ADMIN,
    {
      onCompleted: (response) => {
        if (response?.materialsAdmin?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.materialsAdmin?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.materialsAdmin?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [ageGroups, { loading: isAgeGroupsLoading }] = useLazyQuery(
    AGE_GROUPS,
    {
      onCompleted: (response) => {
        if (response?.ageGroups?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.ageGroups?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.ageGroups?.data, (item) => {
          if (item?.minAge !== 0 || item?.maxAge !== 0) {
            optionsCopy?.push({
              label:
                item?.minAge > 18
                  ? `${Math.floor(item?.minAge / 12)} - ${Math.floor(
                      item?.maxAge / 12,
                    )} Years`
                  : `${item?.minAge} - ${item?.maxAge} Months`,
              key: item?.id,
            });
          }
        });
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  const hasDeletePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS,
    PERMISSION_TYPE?.DELETE,
    permissions,
  );

  useEffect(() => {
    setIsInventoryLoading(true);
    libraryInventoriesAdmin({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          search: inventorySearchTerm,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  }, [libraryId]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsInventoryLoading(true);
    setSortedInfo(sorter);
    libraryInventoriesAdmin({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          skip,
          limit: pagination?.pageSize,
          search: inventorySearchTerm,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setInventorySearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    libraryInventoriesAdmin({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleAddToys = async (values) => {
    let response;
    setIsAddToysLoading(true);
    if (toyFile?.length > 0) {
      const uuid = uuidv4();
      let toyFileName = '';

      if (!values?.toyFile?.url) {
        const { name } = values?.toyFile?.[0];
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toyBulkImport/${uuid}/${newFilename}`;

        const res = await getBulkCreateLibraryInventorySignedPutUrlAdmin({
          variables: {
            data: {
              fileName: fileKey,
              libraryId,
            },
          },
        });

        if (res) {
          const {
            signedUrl,
            fileName,
          } = res?.data?.getBulkCreateLibraryInventorySignedPutUrlAdmin;
          try {
            await fileUpload(
              signedUrl,
              values?.toyFile?.[0]?.originFileObj,
            )?.catch((error) => {
              if (error === 403) {
                openNotification(
                  'error',
                  `${values?.toyFile?.name
                    ?.toString()
                    ?.toUpperCase()} upload failed. Please try again.`,
                );
              }
            });
            toyFileName = fileName;
          } catch (error) {
            setIsBulkUpdateLoading(false);
            return error;
          }
        }
      }
      response = await createBulkLibraryInventory({
        variables: {
          where: {
            id: libraryId,
          },
          data: { key: toyFileName },
        },
      });
    } else {
      response = await addToysToLibraryInventory({
        variables: {
          where: {
            id: libraryId,
          },
          data: {
            toyIds: values?.toys,
          },
        },
      });
    }
    if (response?.data) {
      setIsAddToyModalOpen(false);
      toyForm?.resetFields();
      setToyFile([]);
      setToySelectValue([]);
      setIsInventoryLoading(true);
      libraryInventoriesAdmin({
        variables: {
          where: {
            id: libraryId,
          },
          filter: {
            ...initialToyFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: inventorySearchTerm,
            brandIds: finalWhereFilters?.brand,
            categoryIds: finalWhereFilters?.category,
            facilitateIds: finalWhereFilters?.facilitates,
            tagIds: finalWhereFilters?.tags,
            materialIds: finalWhereFilters?.material,
            ageGroupIds: finalWhereFilters?.ageGroup,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialToySort,
        },
      });
    }
    setIsAddToysLoading(false);
  };

  const handleEditInventoryToys = async (values) => {
    setIsEditInventoryLoading(true);
    const response = await updateLibraryInventoryAdmin({
      variables: {
        where: {
          id: inventory?.id,
        },
        data: {
          availableStock: parseInt(values?.currentlyAvailable, 10),
          reason: values?.reason?.trim(),
          damagedStock: parseInt(values?.damagedStock, 10),
        },
      },
    });
    if (response?.data) {
      setIsEditInventoryModalOpen(false);
      setIsInventoryLoading(true);
      editInventoryForm?.resetFields();
      libraryInventoriesAdmin({
        variables: {
          where: {
            id: libraryId,
          },
          filter: {
            ...initialToyFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: inventorySearchTerm,
            brandIds: finalWhereFilters?.brand,
            categoryIds: finalWhereFilters?.category,
            facilitateIds: finalWhereFilters?.facilitates,
            tagIds: finalWhereFilters?.tags,
            materialIds: finalWhereFilters?.material,
            ageGroupIds: finalWhereFilters?.ageGroup,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialToySort,
        },
      });
    }
    setIsEditInventoryLoading(false);
  };

  const handleDelete = async (id) => {
    setIsDeleteLoading(true);
    const response = await deleteToyFromInventory({
      variables: {
        where: {
          id,
        },
      },
    });
    if (response?.data) {
      setIsDeletePrompts(false);
      setIsInventoryLoading(true);
      const lastPageEle =
        paginationProp?.total % paginationProp?.pageSize === 1 &&
        paginationProp?.total > LIMIT;
      if (lastPageEle) {
        setPaginationProp({
          ...paginationProp,
          current:
            paginationProp?.current - 1 > 0 ? paginationProp?.current - 1 : 1,
        });
      }
      const pageOffset =
        (paginationProp?.current - 1) * (paginationProp?.pageSize || 0);
      const skip = lastPageEle
        ? Math?.max(pageOffset - paginationProp?.pageSize, 0)
        : pageOffset;

      libraryInventoriesAdmin({
        variables: {
          where: {
            id: libraryId,
          },
          filter: {
            ...initialToyFilter,
            skip,
            limit: paginationProp?.pageSize || LIMIT,
            search: inventorySearchTerm,
            brandIds: finalWhereFilters?.brand,
            categoryIds: finalWhereFilters?.category,
            facilitateIds: finalWhereFilters?.facilitates,
            tagIds: finalWhereFilters?.tags,
            materialIds: finalWhereFilters?.material,
            ageGroupIds: finalWhereFilters?.ageGroup,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialToySort,
        },
      });
    }
    setIsDeleteLoading(false);
  };

  const handleExport = async () => {
    setIsExportLoading(true);
    const res = await requestLibraryInventoryExportAdmin({
      variables: {
        where: {
          id: libraryId,
        },
        data: {
          type: EXPORT_TYPES?.LIBRARY_INVENTORY,
        },
        filter: {
          search: exportFilter?.search,
          brandIds: exportFilter?.brand,
          categoryIds: exportFilter?.category,
          facilitateIds: exportFilter?.facilitates,
          tagIds: exportFilter?.tags,
          materialIds: exportFilter?.material,
          ageGroupIds: exportFilter?.ageGroup,
        },
      },
    });
    if (res?.data) {
      setIsGoExportVisible(true);
    }
    setIsExportLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsInventoryLoading(true);
    libraryInventoriesAdmin({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inventorySearchTerm,
          brandIds: whereFilter?.brand,
          categoryIds: whereFilter?.category,
          facilitateIds: whereFilter?.facilitates,
          tagIds: whereFilter?.tags,
          materialIds: whereFilter?.material,
          ageGroupIds: whereFilter?.ageGroup,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    if (!isView) {
      setExportFilter({
        ...exportFilter,
        ...finalWhereFilters,
        ...whereFilter,
      });
    }
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    if (!isView) {
      setExportFilter({
        ...exportFilter,
        ...finalWhereFilters,
        ...whereFiltersCopy,
      });
    }
    setIsInventoryLoading(true);
    libraryInventoriesAdmin({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inventorySearchTerm,
          brandIds: whereFiltersCopy?.brand,
          categoryIds: whereFiltersCopy?.category,
          facilitateIds: whereFiltersCopy?.facilitates,
          tagIds: whereFiltersCopy?.tags,
          materialIds: whereFiltersCopy?.material,
          ageGroupIds: whereFiltersCopy?.ageGroup,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      switch (filterIndex) {
        case 'brand':
          brandsAdmin({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'category':
          categories({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'facilitates':
          facilitatesAdmin({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'tags':
          tagsAdmin({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'material':
          materialsAdmin({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'ageGroup':
          ageGroups({
            variables: {
              variables: {
                filter: initialTableFilter,
                sort: {
                  field: 'minAge',
                  order: SORT?.ASC,
                },
              },
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isBrandsLoading &&
      !isCategoriesLoading &&
      !isMaterialsLoading &&
      !isAgeGroupsLoading &&
      !isFacilitateLoading &&
      !isTagLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'brand':
              brandsAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'category':
              categories({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'facilitates':
              facilitatesAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'tags':
              tagsAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'material':
              materialsAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'ageGroup':
              ageGroups({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength + 1,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'minAge',
                    order: SORT?.ASC,
                  },
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'brand':
        brandsAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'category':
        categories({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'facilitates':
        facilitatesAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'tags':
        tagsAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'material':
        materialsAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'ageGroup':
        ageGroups({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'minAge',
              order: SORT?.ASC,
            },
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35} size="medium">
          {showSearch && (
            <SearchComponent
              className="filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              query={filterSearch}
              setQuery={setFilterSearch}
              getData={(value) => handleFilterSearch(value, dataIndex)}
            />
          )}
          {filterTags?.[dataIndex]?.length > 0 && (
            <div className="filter-tags-wrapper">
              {map(filterTags?.[dataIndex], (item) => (
                <Tag
                  key={item?.key}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  <span>{item?.label}</span>
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onFilterScroll(e, dataIndex)}
          >
            {filterList?.length > 0
              ? map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              : isEmptyFilterList && (
                  <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex, confirm)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const handleBulkUpdate = async (values) => {
    setIsBulkUpdateLoading(true);
    const uuid = uuidv4();
    let bulkUpdateFileName = '';

    if (!values?.bulkUpdateFile?.url) {
      const { name } = values?.bulkUpdateFile?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `bulkUpdate/${uuid}/${newFilename}`;

      const res = await getSignUrl({
        variables: {
          data: {
            fileName: fileKey,
            libraryId,
          },
        },
      });

      if (res) {
        const {
          signedUrl,
          fileName,
        } = res?.data?.getBulkUpdateSignedPutUrlAdmin;
        try {
          await fileUpload(
            signedUrl,
            values?.bulkUpdateFile?.[0]?.originFileObj,
          )?.catch((error) => {
            if (error === 403) {
              openNotification(
                'error',
                `${values?.bulkUpdateFile?.name
                  ?.toString()
                  ?.toUpperCase()} upload failed. Please try again.`,
              );
            }
          });
          bulkUpdateFileName = fileName;
        } catch (error) {
          setIsBulkUpdateLoading(false);
          return error;
        }
      }
    }
    try {
      const response = await libraryInventoryBulkUpdateAdmin({
        variables: { data: { key: bulkUpdateFileName } },
      });
      if (response?.data) {
        setIsBulkUpdateLoading(false);
        setIsBulkUpdateModalOpen(false);
        setBulkUpdateFile([]);
        setIsGoExportVisible(false);
        setIsInventoryLoading(true);
        libraryInventoriesAdmin({
          variables: {
            where: {
              id: libraryId,
            },
            filter: {
              ...initialToyFilter,
              skip:
                (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
              limit: paginationProp?.pageSize || LIMIT,
              search: inventorySearchTerm,
              brandIds: finalWhereFilters?.brand,
              categoryIds: finalWhereFilters?.category,
              materialIds: finalWhereFilters?.material,
              ageGroupIds: finalWhereFilters?.ageGroup,
            },
            sort: {
              ...initialToySort,
              field: sortedInfo?.field || 'createdAt',
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            },
          },
        });
      }
    } catch (error) {
      return error;
    }
    setIsBulkUpdateLoading(false);
  };

  const columns = [
    {
      title: 'PRODUCT NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      onCell: (record) => ({
        onClick: () =>
          isToyPermission &&
          record?.toy?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.toy?.id}`,
          ),
        className: `${
          isToyPermission && record?.toy?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.toy?.name}</div>
          <div className="font-size-12">({record?.toy?.toyNumber})</div>
        </>
      ),
    },
    {
      title: 'IMAGES',
      dataIndex: 'images',
      key: 'images',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.toy?.images?.length > 0 ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.toy?.images?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(record?.toy?.images);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Images</span>
          )}
          {record?.toy?.images?.length > 1
            ? ` + ${record?.toy?.images?.length - 1}`
            : ''}
        </span>
      ),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => PRODUCT_TYPE?.[record?.toy?.type]?.label || '-',
    },
    {
      title: 'DIMENSIONS',
      dataIndex: 'dimensions',
      key: 'dimensions',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.toy?.dimensions || '-',
    },
    {
      title: 'BRAND',
      dataIndex: 'brand',
      key: 'brand',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.toy?.brand?.name,
      ...filterPopup('brand'),
    },
    {
      title: 'MATERIAL',
      dataIndex: 'material',
      key: 'material',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.toy?.material?.name,
      ...filterPopup('material'),
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'category',
      key: 'category',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.categories?.map((category) => (
          <Tag key={category?.id}>{category?.name}</Tag>
        )),
      ...filterPopup('category'),
    },
    {
      title: 'AGE GROUPS',
      dataIndex: 'ageGroups',
      key: 'ageGroups',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.ageGroups?.map((ageGroup) =>
          ageGroup?.minAge > 18 ? (
            <Tag key={ageGroup?.id}>
              {Math.floor(ageGroup?.minAge / 12)} -{' '}
              {Math.floor(ageGroup?.maxAge / 12)} Years
            </Tag>
          ) : (
            <Tag key={ageGroup?.id}>
              {ageGroup?.minAge} - {ageGroup?.maxAge} Months
            </Tag>
          ),
        ),
      ...filterPopup('ageGroup', false),
    },
    {
      title: 'FACILITATES',
      dataIndex: 'facilitates',
      key: 'facilitates',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.facilitates?.length > 0
          ? record?.toy?.facilitates?.map((facilitate) => (
              <Tag key={facilitate?.id}>
                {facilitate?.name?.replace(/\s/g, '\u00a0')}
              </Tag>
            ))
          : '-',
      ...filterPopup('facilitates'),
    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.tags?.length > 0
          ? record?.toy?.tags?.map((tag) => (
              <Tag key={tag?.id}>{tag?.name?.replace(/\s/g, '\u00a0')}</Tag>
            ))
          : '-',
      ...filterPopup('tags'),
    },
    {
      title: 'CURRENTLY AVAILABLE',
      dataIndex: 'currentlyAvailable',
      key: 'currentlyAvailable',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.availableStock,
    },
    {
      title: 'RENTED OUT',
      dataIndex: 'rentedOut',
      key: 'rentedOut',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'DAMAGED STOCK',
      dataIndex: 'damagedStock',
      key: 'damagedStock',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.damagedStock,
    },
    {
      title: 'TOTAL STOCK',
      dataIndex: 'totalStock',
      key: 'totalStock',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.totalStock,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {hasUpdatePermission && (
              <div className="d-flex">
                <Tooltip
                  title="Edit"
                  placement="top"
                  zIndex={4}
                  className="table-action-button"
                >
                  <Button
                    disabled={record?.toy?.isArchived}
                    type="link"
                    onClick={(e) => {
                      e?.stopPropagation();
                      setInventory(record);
                      setIsEditInventoryModalOpen(true);
                    }}
                  >
                    <EditIcon
                      className={
                        record?.toy?.isArchived ? 'svg-icon-grey' : 'svg-icon'
                      }
                    />
                  </Button>
                </Tooltip>
              </div>
            )}
            {hasDeletePermission && (
              <div className="ml-16 d-flex">
                <Tooltip
                  title="Delete"
                  placement="top"
                  zIndex={4}
                  className="table-action-button"
                >
                  <Button
                    type="link"
                    onClick={(e) => {
                      e?.stopPropagation();
                      setInventory(record);
                      setIsDeletePrompts(true);
                    }}
                  >
                    <DeleteIcon className="svg-icon" />
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (
      (item?.dataIndex === 'actions' &&
        !(hasUpdatePermission || hasDeletePermission)) ||
      (item?.dataIndex === 'actions' && isView)
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyInventoryList ? '' : <span />,
  };

  return (
    <>
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <AddToysModal
        form={toyForm}
        onFinish={handleAddToys}
        loadings={isAddToysLoading}
        isModalOpen={isAddToyModalOpen}
        setIsModalOpen={setIsAddToyModalOpen}
        libraryId={libraryId}
        toyFile={toyFile}
        setToyFile={setToyFile}
        toySelectValue={toySelectValue}
        setToySelectValue={setToySelectValue}
      />
      <EditInventoryModal
        form={editInventoryForm}
        onFinish={handleEditInventoryToys}
        loadings={isEditInventoryLoading}
        isModalOpen={isEditInventoryModalOpen}
        setIsModalOpen={setIsEditInventoryModalOpen}
        inventory={inventory}
      />
      <BulkUpdateModal
        form={bulkUpdateForm}
        isModalOpen={isBulkUpdateModalOpen}
        setIsModalOpen={setIsBulkUpdateModalOpen}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
        bulkUpdateFile={bulkUpdateFile}
        setBulkUpdateFile={setBulkUpdateFile}
        onFinish={handleBulkUpdate}
        loadings={isBulkUpdateLoading}
        isGoExportVisible={isGoExportVisible}
        setIsGoExportVisible={setIsGoExportVisible}
        paginationProp={paginationProp}
        isDesktop={isDesktop}
      />
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isDeletePrompts}
        onOk={() => handleDelete(inventory?.id)}
        onCancel={() => setIsDeletePrompts(false)}
        okButtonProps={{ loading: isDeleteLoading }}
      >
        Are you sure you want to <strong>delete</strong> this toy from library's
        inventory?
      </Modal>
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="d-flex align-center">
            {!isView && (
              <Button
                type="primary"
                className="add-toy-btn ml-16"
                onClick={() => setIsBulkUpdateModalOpen(true)}
                disabled={paginationProp?.total === 0}
              >
                Bulk Update
              </Button>
            )}
          </div>
        )}
        {hasPermission(
          PERMISSIONS_KEY?.REPORTS_BULK_UPDATE,
          PERMISSION_TYPE?.READ,
          permissions,
        ) && (
          <div className="d-flex align-center">
            <Button
              type="primary"
              className="add-toy-btn ml-16"
              onClick={() =>
                // eslint-disable-next-line no-undef
                window?.open(
                  `${ROUTES?.REPORTS}${ROUTES?.BULK_UPDATE}?library=${libraryNumber}`,
                  '_blank',
                )
              }
            >
              View Bulk Update Log
            </Button>
          </div>
        )}
        <SearchComponent
          className="search-box ml-16"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <div className="d-flex justify-between mb-16">
        <div>
          <div className="table-top-text">
            Total Stock{' '}
            {parseInt(
              totalToysDetails?.totalAvailableStock
                ? totalToysDetails?.totalAvailableStock
                : 0,
              10,
            ) +
              parseInt(
                totalToysDetails?.totalRentedOut
                  ? totalToysDetails?.totalRentedOut
                  : 0,
                10,
              )}
          </div>
        </div>
        <div>
          <div className="table-top-text">
            Toys Rented Out {totalToysDetails?.totalRentedOut || 0}
          </div>
        </div>
        <div>
          <div className="table-top-text">
            Toys available for rent {totalToysDetails?.totalAvailableStock || 0}
          </div>
        </div>
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={inventoryList || []}
        loading={isInventoryLoading}
        onChange={handleTableChange}
        rowClassName={(record) =>
          record?.toy?.isArchived ? 'archived-row-color' : ''
        }
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default InventoryTable;
