import { Button, Divider, Form, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import LibrarySelect from '../../../../lists/modules/library/components/LibrarySelect';

const { Item } = Form;
const { required } = formValidatorRules;

const MappedLibModal = ({
  isModalOpen,
  setIsModalOpen,
  setEndUser,
  onFinish,
  loadings,
  endUser,
  form,
  title,
  handleRemoveMappedLib,
  additionalLibrary,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setEndUser(null);
    form?.resetFields();
  };

  useEffect(() => {
    const initialValues = {
      name: additionalLibrary?.library?.id,
    };
    form?.setFieldsValue(initialValues);
  }, [endUser]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={title}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        footer={additionalLibrary ? null : undefined}
      >
        <Divider />
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Library Name"
            name="name"
            rules={[{ ...required, message: 'Please Select Library Name!' }]}
          >
            <LibrarySelect
              libraryData={additionalLibrary?.library}
              isActiveLibOnly
              disabled={additionalLibrary}
            />
          </Item>
          {additionalLibrary && (
            <div className="d-flex justify-end">
              <Popconfirm
                title="Are you sure you want to remove this mapped library?"
                onConfirm={handleRemoveMappedLib}
                okText="Yes"
                cancelText="No"
                okButtonProps={{ loading: loadings }}
              >
                <Button type="primary" danger>
                  Remove
                </Button>
              </Popconfirm>
            </div>
          )}
        </Form>
      </ModalComponent>
    </>
  );
};

export default MappedLibModal;
