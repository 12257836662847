import { ExportOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import {
  EXPORT_TYPES,
  MODULES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SYNC_LIBRARY_INVENTORY_TIME,
} from '../../../../../common/constants';
import {
  fileUpload,
  hasPermission,
  openNotification,
} from '../../../../../common/utils';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import Portal from '../../../../../components/Portal';
import BulkUpdateModal from '../components/BulkUpdateModal';
import ToyTable from '../components/ToyTable';
import {
  REQUEST_TOY_DATA_EXPORT,
  REQUEST_TOY_EXPORT,
  SYNC_LIBRARY_INVENTORY,
  TOY_BULK_UPDATE,
} from '../graphql/Mutations';
import { GET_SIGNED_TOYS_UPLOAD_URL } from '../graphql/Queries';

const oneHour = 60 * 60 * 1000;

const Toy = () => {
  const {
    state: { permissions, isDesktop },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [exportFilter, setExportFilter] = useState();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bulkUpdateForm] = Form.useForm();
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const [isBulkUpdateLoading, setIsBulkUpdateLoading] = useState(false);
  const [bulkUpdateFile, setBulkUpdateFile] = useState([]);
  const [isBulkExportLoading, setIsBulkExportLoading] = useState(false);
  const [isGoExportVisible, setIsGoExportVisible] = useState(false);
  const [isSyncInventoryPrompts, setIsSyncInventoryPrompts] = useState(false);
  const [isSyncInventoryLoading, setIsSyncInventoryLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [requestUserExport] = useMutation(REQUEST_TOY_EXPORT, {
    onError: () => {},
  });
  const [requestToyDataExport] = useMutation(REQUEST_TOY_DATA_EXPORT, {
    onError: () => {},
  });
  const [getSignUrl] = useLazyQuery(GET_SIGNED_TOYS_UPLOAD_URL, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  const [libraryInventoryBulkUpdate] = useMutation(TOY_BULK_UPDATE, {
    onError: () => {},
  });
  const [syncLibraryInventory] = useMutation(SYNC_LIBRARY_INVENTORY, {
    onError: () => {},
  });

  const handleBulkUpdate = async (values) => {
    setIsBulkUpdateLoading(true);
    const uuid = uuidv4();
    let bulkUpdateFileName = '';

    if (!values?.bulkUpdateFile?.url) {
      const { name } = values?.bulkUpdateFile?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `bulkUpdate/${uuid}/${newFilename}`;

      const res = await getSignUrl({
        variables: {
          data: {
            fileName: fileKey,
          },
        },
      });

      if (res) {
        const { signedUrl, fileName } = res?.data?.getToyUploadSignedPutUrl;
        try {
          await fileUpload(
            signedUrl,
            values?.bulkUpdateFile?.[0]?.originFileObj,
          )?.catch((error) => {
            if (error === 403) {
              openNotification(
                'error',
                `${values?.bulkUpdateFile?.name
                  ?.toString()
                  ?.toUpperCase()} upload failed. Please try again.`,
              );
            }
          });
          bulkUpdateFileName = fileName;
        } catch (error) {
          setIsBulkUpdateLoading(false);
          return error;
        }
      }
    }
    try {
      const response = await libraryInventoryBulkUpdate({
        variables: { data: { key: bulkUpdateFileName } },
      });
      if (response?.data) {
        // setIsInventoryLoading(true);
        setIsBulkUpdateLoading(false);
        setIsBulkUpdateModalOpen(false);
        setBulkUpdateFile([]);
        setIsGoExportVisible(false);
        // libraryInventories({
        //   variables: {
        //     where: {
        //       id: libraryId,
        //     },
        //     filter: {
        //       ...initialToyFilter,
        //       skip:
        //         (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
        //       limit: paginationProp?.pageSize || LIMIT,
        //       search: inventorySearchTerm,
        //       brandIds: finalWhereFilters?.brand,
        //       categoryIds: finalWhereFilters?.category,
        //       materialIds: finalWhereFilters?.material,
        //       ageGroupIds: finalWhereFilters?.ageGroup,
        //     },
        //     sort: {
        //       ...initialToySort,
        //       field: sortedInfo?.field || 'createdAt',
        //       order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
        //     },
        //   },
        // });
      }
    } catch (error) {
      return error;
    }
    setIsBulkUpdateLoading(false);
  };
  const handleBulkExport = async () => {
    setIsBulkExportLoading(true);
    const res = await requestToyDataExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.TOY_BULK_UPLOAD,
        },
      },
    });
    if (res?.data) {
      setIsGoExportVisible(true);
    }
    setIsBulkExportLoading(false);
  };
  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestUserExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.TOY,
        },
        filter: {
          search: exportFilter?.search,
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
          brandIds: exportFilter?.brand,
          categoryIds: exportFilter?.category,
          materialIds: exportFilter?.material,
          ageGroupIds: exportFilter?.ageGroup,
          isArchived: exportFilter?.isArchived,
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  const handleSync = async () => {
    setIsSyncInventoryLoading(true);
    await syncLibraryInventory();
    const currentTime = new Date().getTime();
    // eslint-disable-next-line no-undef
    localStorage.setItem(SYNC_LIBRARY_INVENTORY_TIME, currentTime);
    setIsSyncInventoryLoading(false);
    setIsDisabled(true);
    setIsSyncInventoryPrompts(false);
  };

  const checkSyncState = () => {
    // eslint-disable-next-line no-undef
    const lastSyncTime = localStorage.getItem(SYNC_LIBRARY_INVENTORY_TIME);
    if (lastSyncTime) {
      const currentTime = new Date().getTime();
      if (currentTime - lastSyncTime < oneHour) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    checkSyncState();
  }, []);

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isSyncInventoryPrompts}
        onOk={handleSync}
        onCancel={() => {
          setIsSyncInventoryPrompts(false);
        }}
        okButtonProps={{ loading: isSyncInventoryLoading }}
      >
        Are you sure you want to Sync Library Inventory?
      </Modal>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.TOY}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_TOY,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() =>
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/add`)
            }
          >
            Add New Toy
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          onClick={() => setIsSyncInventoryPrompts(true)}
          icon={<SyncOutlined />}
          disabled={isDisabled}
        >
          Sync Library Inventory
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          onClick={() => setIsBulkUpdateModalOpen(true)}
        >
          Bulk Update
        </Button>
        <BulkUpdateModal
          form={bulkUpdateForm}
          isModalOpen={isBulkUpdateModalOpen}
          setIsModalOpen={setIsBulkUpdateModalOpen}
          handleExport={handleBulkExport}
          isExportLoading={isBulkExportLoading}
          bulkUpdateFile={bulkUpdateFile}
          setBulkUpdateFile={setBulkUpdateFile}
          onFinish={handleBulkUpdate}
          loadings={isBulkUpdateLoading}
          isGoExportVisible={isGoExportVisible}
          setIsGoExportVisible={setIsGoExportVisible}
          isDesktop={isDesktop}
        />
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_TOY,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() =>
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/add`)
            }
          >
            Add New Toy
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          onClick={() => setIsSyncInventoryPrompts(true)}
          icon={<SyncOutlined />}
          disabled={isDisabled}
        >
          Sync Library Inventory
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          onClick={() => setIsBulkUpdateModalOpen(true)}
        >
          Bulk Update
        </Button>
      </Portal>
      <ExportDateRangeModal
        form={form}
        onFinish={handleExport}
        loadings={isExportLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Card className="full-height-without-nav">
        <ToyTable
          exportFilter={exportFilter}
          setExportFilter={setExportFilter}
        />
      </Card>
    </>
  );
};

export default Toy;
